import { useEffect, useState } from 'react'
import gsap, { Sine } from 'gsap'
import { useGSAP } from '@gsap/react'
import * as VFX from 'react-vfx';
import { isMobile, isTablet } from 'react-device-detect'
import '@fontsource/inter'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/inter/900.css'

import Layout from './components/Layout'
import Menu from './components/Menu'
import Home from './sections/Home'
import Works from './sections/Works'
import Archive from './sections/Archive'
import Achievements from './sections/Achievements'
import Footer from './sections/Footer'

const App = () => {
  const [isMoon, setIsMoon] = useState(false)

  useGSAP(() => {
    const randomX = random(-400, 400)
    const randomY = random(-200, 200)
    const randomTime = random(6, 12)
    const randomTime2 = random(5, 6)
    const randomAngle = random(-30, 150)

    const blurs = gsap.utils.toArray('.blur')
    blurs.forEach((blur) => {
      gsap.set(blur, {
        x: randomX(-1),
        y: randomX(1),
        rotation: randomAngle(-1)
      })
      moveX(blur, 1)
      moveY(blur, -1)
      rotate(blur, 1)
    })

    function rotate(target, direction) {
      gsap.to(target, randomTime2(), {
        rotation: randomAngle(direction),
        ease: Sine.easeInOut,
        onComplete: rotate,
        onCompleteParams: [target, direction * -1]
      })
    }

    function moveX(target, direction) {
      gsap.to(target, randomTime(), {
        x: randomX(direction),
        ease: Sine.easeInOut,
        onComplete: moveX,
        onCompleteParams: [target, direction * -1]
      })
    }

    function moveY(target, direction) {
      gsap.to(target, randomTime(), {
        y: randomY(direction),
        ease: Sine.easeInOut,
        onComplete: moveY,
        onCompleteParams: [target, direction * -1]
      })
    }

    function random(min, max) {
      const delta = max - min
      return (direction = 1) => (min + delta * Math.random()) * direction
    }
  }, [])

  useEffect(() => {
    if (isMobile || isTablet) {
      return
    }
    const links = document.querySelectorAll('.link')
    links.forEach(link => {
      link.addEventListener('mouseenter', () => {
        const originalText = link.textContent
        let shuffleInterval = setInterval(() => {
          link.textContent = generateRandomText(originalText.length)
        }, 100) // Adjust the shuffle speed
        link.addEventListener('mouseleave', () => {
          clearInterval(shuffleInterval)
          link.textContent = originalText // Restore the original text
        }, { once: true })
      })
    })
    function generateRandomText(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()'
      let randomText = ''
      for (let i = 0; i < length; i++) {
        randomText += characters.charAt(Math.floor(Math.random() * characters.length))
      }
      return randomText
    }
  }, [])

  useEffect(() => {
    const checkIfAtBottom = () => {
      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 90
      document.body.classList.toggle('is-bottom', isAtBottom)
    }
    checkIfAtBottom()
    window.addEventListener('scroll', checkIfAtBottom)
    window.addEventListener('resize', checkIfAtBottom)
    return () => {
      window.removeEventListener('scroll', checkIfAtBottom)
      window.removeEventListener('resize', checkIfAtBottom)
    };
  }, []);

  return (
    <VFX.VFXProvider>
    <Layout>
      <main className='App'>
        <Home isMoon={isMoon} />
        <Works />
        <div style={{ position: 'relative', background: '#0d1116' }}>
          <Archive />
          <Achievements />
          <Footer setIsMoon={setIsMoon} />
          <div className='gradient__background_container'>
            <div className='gradient__background'>
              <div className='blur' />
              <div className='blur' />
              <div className='blur' />
              <div className='meteors'>
                <div className='meteor meteor-1'></div>
                <div className='meteor meteor-2'></div>
                <div className='meteor meteor-3'></div>
                <div className='meteor meteor-4'></div>
                <div className='meteor meteor-5'></div>
                <div className='meteor meteor-6'></div>
                <div className='meteor meteor-7'></div>
                <div className='meteor meteor-8'></div>
                <div className='meteor meteor-9'></div>
                <div className='meteor meteor-10'></div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Menu />
    </Layout>
    </VFX.VFXProvider>
  )
}

export default App
