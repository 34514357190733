import { useEffect, useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { VFX } from '@vfx-js/core'
import { VFXVideo, VFXImg } from 'react-vfx'

import styles from '../styles/sections/Achievements.module.scss'

const Achievements = () => {
  const [currentSlide, setCurrentSlide] = useState('achievements1')

  useEffect(() => {
    const getCurrentSlide = () => {
      const achievements1 = document.querySelector('#achievements-1')
      const achievements2 = document.querySelector('#achievements-2')
      const achievements1Rect = achievements1.getBoundingClientRect()
      const achievements2Rect = achievements2.getBoundingClientRect()
      if (achievements1Rect.top >= 0 && achievements2Rect.top >= 0) {
        setCurrentSlide('achievements1')
      } else if (achievements1Rect.top <= 0 && achievements2Rect.top <= 0) {
        setCurrentSlide('achievements2')
      } else {
        Math.abs(achievements2Rect.top) + window.innerHeight / 3 > Math.abs(achievements1Rect.top)
          ? setCurrentSlide('achievements1')
          : setCurrentSlide('achievements2')
      }
    }
    getCurrentSlide()
    document.addEventListener('scroll', getCurrentSlide)
    document.addEventListener('resize', getCurrentSlide)
    return () => {
      document.removeEventListener('scroll', getCurrentSlide)
      document.removeEventListener('resize', getCurrentSlide)
    }
  }, [])

  useEffect(() => {
    if (isMobile || isTablet) {
      return
    }
    if (window.innerWidth >= 991.98) {
      const vfx = new VFX()
      const el = document.querySelector(`.${styles.achievements__asset} video`)
      const el2 = document.querySelector(`.${styles.achievements__asset} img`)
      if (currentSlide === 'achievements1')
        vfx.add(el, { shader: "warpTransition" })
      if (currentSlide === 'achievements2')
        vfx.add(el2, { shader: "warpTransition" })
    }
  }, [currentSlide])

  return (
    <section id='achievements' className={styles.achievements}>
      <div id='achievements-1' className={styles.achievements__item}>
        <div className={styles.achievements__item_heading}>Achievements</div>
        <div className={styles.achievements__item_label}>
          01 / 02
        </div>
        <div className={styles.achievements__item_asset}>
          <video
            autoPlay
            loop
            muted
            playsInline
            shader='warpTransition'
          >
            <source src='/alchemy.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.achievements__item_title}>
          Alchemy Certified <br /> Ethereum <br /> Developer
        </div>
        <div className={styles.achievements__item_list}>
          <div>Blockchain Cryptography</div>
          <div>Ethererum Node API</div>
          <div>Smart Contract Development</div>
          <div>Solidity</div>
        </div>
      </div>
      <div id='achievements-2' className={styles.achievements__item}>
        <div className={styles.achievements__item_label}>
          02 / 02
        </div>
        <div className={styles.achievements__item_asset}>
          <img
            className='preload-img'
            src='/solana.gif'
            alt=''
            shader='warpTransition'
            />
        </div>
        <div className={styles.achievements__item_title}>
          Solana Taipei<br />Hacker House <br /> The Challenger <br /> Rank #4
        </div>
        <div className={styles.achievements__item_list}>
          <div>Client Side Developement</div>
          <div>SPL Token and Minting NFTs</div>
          <div>Rust and Native Solana Development</div>
          <div>PDAs, CPIs and Testing</div>
        </div>
      </div>
      <div className={styles.achievements__asset_container}>
        <div className={styles.achievements__asset}>
          <VFXVideo
            autoPlay
            loop
            muted
            playsInline
            className={currentSlide === 'achievements1' ? 'active' : ''}
            shader='warpTransition'
          >
            <source src='/alchemy.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </VFXVideo>
          <VFXImg
            className={currentSlide === 'achievements2' ? 'active' : ''}
            src='/solana.gif'
            alt=''
            shader='warpTransition'
          />
        </div>
      </div>
    </section>
  )
}

export default Achievements
