const imagesLoaded = require('imagesloaded')

export const preloader = () => {
  return new Promise(resolve => {
    imagesLoaded(document.querySelectorAll('.preload-img'), () => {
      setTimeout(() => {
        document.body.classList.remove('loading')
        resolve()
      }, [1200])
    })
  })
}
