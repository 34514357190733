import { useRef } from 'react'
import { useScroll, useTransform, motion } from 'framer-motion'
import { useLenis } from 'lenis/react'

import styles from '../styles/sections/Footer.module.scss'

const SLOGAN = 'BUIDL the future of the decentralized web'

const Char = ({ children, progress, range }) => {
  const opacity = useTransform(progress, range, [0, 1])
  return (
    <span>
      <span className={styles.shadow}>{children}</span>
      <motion.span style={{ opacity: opacity }}>{children}</motion.span>
    </span>
  )
}

const Word = ({ children, progress, range }) => {
  const amount = range[1] - range[0]
  const step = amount / children.length
  return (
    <span className={styles.word}>
      {
        children.split('').map((char, i) => {
          const start = range[0] + (i * step);
          const end = range[0] + ((i + 1) * step)
          return <Char key={`c_${i}`} progress={progress} range={[start, end]}>{char}</Char>
        })
      }
    </span>
  )
}

const Footer = ({ setIsMoon }) => {
  const container = useRef(null)
  const lenis = useLenis()
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ['start 0', 'end 1.2'],
  })
  const words = SLOGAN.split(' ')

  return (
    <section id="footer" className={styles.footerContainer} ref={container}>
      <div className={styles.footer__top_bar}>
        <div onClick={() => {
          setIsMoon(true)
          const home = document.querySelector('#home')
          lenis.scrollTo(home)
        }}>[ <span className='link'>To The Moon</span> ]</div>
      </div>
      <motion.div className={styles.footer}>
        <p className={styles.paragraph}>
          {
            words.map((word, i) => {
              const start = i / words.length
              const end = start + (1 / words.length)
              return <Word key={i} progress={scrollYProgress} range={[start, end]}>{word}</Word>
            })
          }
        </p>
      </motion.div>
      <div className={styles.footer__bottom_bar}>
        <div className={styles.footer__bottom_item}>
          <div>( SOCIALS )</div>
          <div>
            <a target='_blank' rel='noopener noreferrer' href='https://x.com/sunsquarez'>X</a>
          </div>
        </div>
        <div className={styles.footer__bottom_item}>
          <div>( REACH OUT )</div>
          <div>
            <a href='mailto:gm@0xhanson.xyz'>GM@0XHANSON.XYZ</a>
          </div>
        </div>
        <div className={styles.footer__bottom_item}>
          <div>( DEVELOPMENT )</div>
          <div>
            <a target='_blank' rel='noopener noreferrer' href='https://hanson-wu.com'>HANSON WU</a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Footer
