import { useLenis } from 'lenis/react'
import { VFXSpan } from 'react-vfx'
import { useState, useEffect } from 'react'

import ParallaxTexts from '../components/ParallaxTexts'
import ParticleAvatar from '../components/ParticleAvatar'
import styles from '../styles/sections/Home.module.scss'

const TEXTS = [
  'DAPP DEVELOPMENT',
  '',
  'SMART CONTRACT DEVELOPMENT',
  '',
  '( EST 2024 )',
  '[ GM ]',
]

const Home = ({ isMoon }) => {
  const lenis = useLenis()
  const [isReady, setIsReady] = useState(false)

  const hour = new Date().getHours()
  if (hour >= 18 || hour < 6) {  // Assuming night time is between 6 PM and 6 AM
    TEXTS[5] = '[ GN ]'
  }

  useEffect(() => {
    setTimeout(() => {
      setIsReady(true)
    }, [2000])
  }, [])

  return (
    <section id='home' className={styles.home}>
      <div className={styles.home__container}>
        <div className={styles.home__header}>
          <div>created by<a target='_blank' rel='noopener noreferrer' href='https://hanson-wu.com'>Hanson Wu</a></div>
          <div>🌝 available for projects</div>
        </div>
        <div className={styles.home__parallax_texts}>
          <ParallaxTexts texts={TEXTS} />
        </div>
        {isReady && (
          <>
            <div className={styles.home__particle_avatar}>
              <ParticleAvatar isMoon={isMoon} />
            </div>
            <div className={styles.home__content}>
              <VFXSpan shader='rgbShift' className={styles.home__subheading}>Web3 Dev Portfolio</VFXSpan>
              <h1 className={styles.home__heading}>0xHanson</h1>
            </div>
          </>
        )}
        <div className={styles.home__scroll} onClick={() => {
          const works = document.querySelector('#works')
          lenis.scrollTo(works)
        }}>
          <svg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.42857 0H0V3.25H3.42857V6.5H6.85714V9.75H10.2857V13H13.7143V9.75H17.1429V6.5H20.5714V3.25H24V0H20.5714V3.25H17.1429V6.5H13.7143V9.75H10.2857V6.5H6.85714V3.25H3.42857V0Z' fill='#c5c5c6'></path>
          </svg>
        </div>
      </div>
    </section>
  )
}

export default Home
