import { useRef, useEffect } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { ReactLenis } from 'lenis/react'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'

import { preloader } from '../utils/preloader'

export default function Layout({ children }) {
  let cursor = useRef(null)
  let posX1 = useRef(0)
  let posY1 = useRef(0)
  let mouseX1 = useRef(0)
  let mouseY1 = useRef(0)

  let tl = gsap.timeline()
  let tl2 = gsap.timeline()

  useGSAP(() => {
    if (isMobile || isTablet) {
      return
    }

    let posX = posX1.current
    let posY = posY1.current
    let mouseX = mouseX1.current
    let mouseY = mouseY1.current
    let lastX = posX
    let lastY = posY
    let mouseMoveTimeout;

    tl.to({}, 0.016, {
      repeat: -1,
      onRepeat: function () {
        posX += (mouseX - posX) / 10
        posY += (mouseY - posY) / 10
        let diffX = posX - lastX
        let diffY = posY - lastY
        let rotation = Math.min(Math.max(diffX - diffY, -10), 10)

        if (cursor.current) {
          gsap.set(cursor.current, {
            left: posX,
            top: posY - 30,
            transform: `rotateZ(${rotation}deg)`
          })
        }

        lastX = posX
        lastY = posY
      }
    })

    const updateMousePosition = (e) => {
      if (mouseMoveTimeout) return;
      mouseMoveTimeout = requestAnimationFrame(() => {
        const scrollY = window.scrollY || window.pageYOffset;
        mouseX = e.pageX;
        mouseY = e.pageY - scrollY;
        mouseMoveTimeout = null;
      });
    };
    
    document.addEventListener('mousemove', updateMousePosition);

    tl2.from(cursor.current, {
      duration: 1.5,
      delay: 2,
      opacity: 0
    }, '-=1')

    return () => {
      document.removeEventListener('mousemove', updateMousePosition)
    }
  }, [])

  useEffect(() => {
    const selector = document.querySelector('body')
    if (isMobile) {
      selector.classList.add('is-mobile')
    }
    if (isTablet) {
      selector.classList.add('is-tablet')
    }
  }, [])
  
  useEffect(() => {
    preloader()
  }, [])

  return (
    <ReactLenis root>
      <div className='layout'>
        {children}
      </div>
      <div className='decorator'>
        {/* <div className='noise' /> */}
        {!isMobile && !isTablet && (
          <div className='cursor-image' ref={cursor} />
        )}
      </div>
    </ReactLenis>
  )
}
