import { useLenis } from 'lenis/react'
import { useEffect } from 'react'
import Marquee from 'react-fast-marquee'

import styles from '../styles/sections/Works.module.scss'

const SOL_SKILLS = [
  'Solana',
  'Metaplex',
  'Candy Machine',
  'Program',
  'Minting UI',
]

const CREATING_ART_SKILLS = [
  'Creative Coding',
  'Generative Art',
  'p5.js',
  'Tezos',
]

const THESIS_SKILLS = [
  'EVM',
  'Smart Contract',
  'Account Abstraction',
  'Gamification',
]

const SLIDE_COUNT = 3;

const setupSlides = () => {
  const coverSlides = document.querySelector(`.${styles.works}`)
  const sectionTop = coverSlides.offsetTop;
  const viewportTop = window.scrollY;
  const offset = window.innerHeight;

  const slideIndex = Math.min(
    SLIDE_COUNT - 1,
    Math.floor((viewportTop - sectionTop) / offset),
  )

  const currentSlide = document.querySelector(
    `.${styles.works} .${styles.works__container}.active`,
  )
  const targetChild = document.querySelector(
    `.${styles.works} .${styles.works__container}:nth-child(${slideIndex + 1})`,
  )

  if (targetChild && targetChild !== currentSlide) {
    currentSlide && currentSlide.classList.remove('active')
    targetChild.classList.add('active')
  }
}

const Works = () => {
  const lenis = useLenis()

  useEffect(() => {
    setupSlides()
    document.addEventListener('scroll', setupSlides)
    document.addEventListener('resize', setupSlides)
    return () => {
      document.removeEventListener('scroll', setupSlides)
      document.removeEventListener('resize', setupSlides)
    }
  }, [])

  return (
    <section id='works' className={styles.works}>
      <div id='works-1' className={`${styles.works__container} slide-1 active`}>
        <div className={styles.works__asset}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          >
            <source src='/fengli.mp4' type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.works__label}>Scroll to Explore</div>
        <div className={styles.works__content}>
          <div className={styles.works__subheading}>Mini Project</div>
          <div className={styles.works__asset_wrapper}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            >
              <source src='/fengli.mp4' type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <h2 className={styles.works__heading}>Feng Li<br />Candy Machine</h2>
          <div className={styles.works__text}>Built at Solana HackerHouse Taipei with <a target='_blank' rel='noopener noreferrer' href='https://0xa2m.xyz'>0xA2M</a>, discover the essence of Taiwan's pineapple through our exclusive NFT collection. Own a piece of the commemorative FengLi NFT and indulge in a unique slice of tropical paradise, redefined in digital art.</div>
          <div>
            <a target='_blank' rel='noopener noreferrer' href='https://fengli-candy-machine-ui.vercel.app/' className='custom-btn neon-btn'>View More</a>
          </div>
          <div className={styles.works__skills}>
            <Marquee direction='left' speed={55}>
              {SOL_SKILLS.map((item) => (
                <div className={styles.works__skill} key={item}>
                  {item}
                </div>
              ))}
            </Marquee>
          </div>
        </div>
        <div className={styles.works__scroll} onClick={() => {
          const works = document.querySelector('#works')
          window.scrollTo({
            top: works.offsetTop + window.innerHeight,
            behavior: 'instant',
          })
        }}>
          <svg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.42857 0H0V3.25H3.42857V6.5H6.85714V9.75H10.2857V13H13.7143V9.75H17.1429V6.5H20.5714V3.25H24V0H20.5714V3.25H17.1429V6.5H13.7143V9.75H10.2857V6.5H6.85714V3.25H3.42857V0Z' fill='#c5c5c6'></path>
          </svg>
        </div>
      </div>
      <div id='works-2' className={`${styles.works__container} slide-2`}>
        <div className={styles.works__asset}>
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          >
            <source src='/desurvey.mov' type='video/mp4' />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className={styles.works__label}>Scroll to Explore</div>
        <div className={styles.works__content}>
          <div className={styles.works__subheading}>MVP dApp</div>
          <div className={styles.works__asset_wrapper}>
            <video
              autoPlay
              loop
              muted
              playsInline
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            >
              <source src='/desurvey.mov' type='video/mp4' />
              Your browser does not support the video tag.
            </video>
          </div>
          <h2 className={styles.works__heading}>DeSurvey</h2>
          <div className={styles.works__text}>Built as part of my <a target='_blank' rel='noopener noreferrer' href='https://hdl.handle.net/11296/vuq3zd'>thesis paper</a>, a decentralized survey platform designed to enhance data security, address trust concerns, and boost user engagement through gamification. By integrating blockchain technology, it ensures secure incentive delivery, fosters transparency, and redefines surveys with features like NFT badges and token rewards.</div>
          <a target='_blank' rel='noopener noreferrer' href='https://desurvey-dapp.vercel.app/' className='custom-btn neon-btn'>View More</a>
          <div className={styles.works__skills}>
            <Marquee direction='left' speed={55}>
              {THESIS_SKILLS.map((item) => (
                <div className={styles.works__skill} key={item}>
                  {item}
                </div>
              ))}
            </Marquee>
          </div>
        </div>
        <div className={styles.works__scroll} onClick={() => {
          const works = document.querySelector('#works')
          window.scrollTo({
            top: works.offsetTop + window.innerHeight * 2,
            behavior: 'instant',
          })
        }}>
          <svg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.42857 0H0V3.25H3.42857V6.5H6.85714V9.75H10.2857V13H13.7143V9.75H17.1429V6.5H20.5714V3.25H24V0H20.5714V3.25H17.1429V6.5H13.7143V9.75H10.2857V6.5H6.85714V3.25H3.42857V0Z' fill='#c5c5c6'></path>
          </svg>
        </div>
      </div>
      <div id='works-3' className={`${styles.works__container} slide-3`}>
        <div className={styles.works__asset}>
          <iframe title='html-embed' id='embedded-iframe' src='/likewater24' sandbox='allow-scripts allow-same-origin allow-downloads allow-modals allow-popups' allow='accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;' scrolling='no'></iframe>
        </div>
        <div className={styles.works__label}>Scroll to Explore</div>
        <div className={styles.works__content}>
          <div className={styles.works__subheading}>Creative Coding</div>
          <div className={styles.works__asset_wrapper}>
            <iframe title='html-embed' id='embedded-iframe' src='/likewater24' sandbox='allow-scripts allow-same-origin allow-downloads allow-modals allow-popups' allow='accelerometer; camera; gyroscope; microphone; xr-spatial-tracking;' scrolling='no'></iframe>
          </div>
          <h2 className={styles.works__heading}>Impermanence Flow</h2>
          <div className={styles.works__text}>P5.js Creation issued on akaSwap. Through continuously flowing and converging visual elements, it symbolizes the impermanence of life and the passage of time. As the Greek philosopher Heraclitus once said, 'No man ever steps in the same river twice, for it is not the same river, and he is not the same man.'</div>
          <a target='_blank' rel='noopener noreferrer' href='https://akaswap.com/akaobj/25147' className='custom-btn neon-btn'>View More</a>
          <div className={styles.works__skills}>
            <Marquee direction='left' speed={55}>
              {CREATING_ART_SKILLS.map((item) => (
                <div className={styles.works__skill} key={item}>
                  {item}
                </div>
              ))}
            </Marquee>
          </div>
        </div>
        <div className={styles.works__scroll} onClick={() => {
          const achievements = document.querySelector('#achievements')
          lenis.scrollTo(achievements)
        }}>
          <svg width='24' height='13' viewBox='0 0 24 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M3.42857 0H0V3.25H3.42857V6.5H6.85714V9.75H10.2857V13H13.7143V9.75H17.1429V6.5H20.5714V3.25H24V0H20.5714V3.25H17.1429V6.5H13.7143V9.75H10.2857V6.5H6.85714V3.25H3.42857V0Z' fill='#c5c5c6'></path>
          </svg>
        </div>
      </div>
    </section>
  )
}

export default Works
