import { useState, useEffect } from 'react'
import { useLenis } from 'lenis/react'

import styles from '../styles/components/Menu.module.scss'

const Menu = () => {
  const [currentSection, setCurrentSection] = useState('home')
  const lenis = useLenis()

  useEffect(() => {
    const getCurrentSection = () => {
      const home = document.querySelector('#home')
      const works = document.querySelector('#works')
      const achievements = document.querySelector('#achievements')
      const footer = document.querySelector('#footer')
      const homeRect = home.getBoundingClientRect()
      const worksRect = works.getBoundingClientRect()
      const achievementsRect = achievements.getBoundingClientRect()
      const footerRect = footer.getBoundingClientRect()
      const sections = [
        { section: 'home', top: homeRect.top - window.innerHeight / 2 },
        { section: 'works', top: worksRect.top - window.innerHeight / 2 },
        { section: 'achievements', top: achievementsRect.top - window.innerHeight / 2 },
        { section: 'footer', top: footerRect.top - window.innerHeight / 1.2 }
      ]
      const closestToZeroNegative = sections.reduce((min, current) => 
        (current.top <= 0 && current.top > min.top) ? current : min, { top: -Infinity })
      setCurrentSection(closestToZeroNegative.section)
    }
    getCurrentSection()
    document.addEventListener('scroll', getCurrentSection)
    document.addEventListener('resize', getCurrentSection)
    return () => {
      document.removeEventListener('scroll', getCurrentSection)
      document.removeEventListener('resize', getCurrentSection)
    }
  }, [])

  return (
    <nav className={currentSection === 'footer' ? `${styles.menu} is-hidden` : styles.menu}>
      <ul className={styles.menu__list}>
        <li className={currentSection === 'home' ? 'active link' : 'link'} onClick={() => {
          const home = document.querySelector('#home')
          lenis.scrollTo(home)
        }}>
          HOME
        </li>
        <li className={currentSection === 'works' ? 'active link' : 'link'} onClick={() => {
          const works = document.querySelector('#works')
          lenis.scrollTo(works)
        }}>
          WORKS
        </li>
        <li className={currentSection === 'achievements' ? 'active link' : 'link'} onClick={() => {
          const achievements = document.querySelector('section#achievements')
          lenis.scrollTo(achievements)
        }}>
          ACHIEVEMENTS
        </li>
      </ul>
    </nav>
  )
}

export default Menu
