import { useEffect } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { VFX } from '@vfx-js/core'

import styles from '../styles/sections/Archive.module.scss'

const shader = `
precision highp float;
uniform vec2 resolution;
uniform vec2 offset;
uniform float time;
uniform sampler2D src;
uniform float scroll;
out vec4 outColor;

void main (void) {
    vec2 uv = (gl_FragCoord.xy - offset) / resolution;

    // Scroll X
    uv.x = fract(uv.x + scroll + time * 0.2);

    outColor = texture2D(src, uv);
}
`;

const mobileShader = `
uniform vec2 resolution;
uniform vec2 offset;
uniform float scroll; // custom uniform passed as props
uniform sampler2D src;
out vec4 outColor;

void main() {
    vec2 uv = (gl_FragCoord.xy - offset) / resolution;

    // scroll X by scroll
    uv.x = fract(uv.x + scroll * 15.);

    // prevent vertical overflow
    if (uv.y < 0. || uv.y > 1.) discard;

    outColor = texture2D(src, uv);
}
`;

const Archive = () => {
  useEffect(() => {
    const vfx = new VFX()
    const el = document.querySelector('#archive-heading')
    vfx.add(el, {
        shader: isMobile || isTablet
          ? mobileShader
          : shader,
        uniforms: {
          // Uniform functions are evaluated every frame
          scroll: () => {
            if (isMobile || isTablet) {
              return window.scrollY / (document.body.scrollHeight - window.innerHeight)
            } else {
              return window.scrollY / window.innerHeight
            }
          },
        }
    });
  }, [])

  return (
    <section id='archive' className={styles.archive}>
      <div id='archive-heading' className={styles.archive__heading}>
        Probably nothing
      </div>
    </section>
  )
}

export default Archive
