import { useRef, useEffect, useMemo, memo } from 'react'

import styles from './ParallaxTexts.module.scss'

const ParallaxTexts = ({ texts }) => {
  const wrapperRef = useRef(null)

  const randomHoverPositions = useMemo(
    () => texts.map(() => Math.random() * (3 - 1) + 1),
    [texts]
  )
  
  const randomFloatingDurations = useMemo(
    () => texts.map(() => Math.random() * (5 - 4) + 4),
    [texts]
  )

  const intensity = 80

  useEffect(() => {
    const parallax = (event) => {
      const elements = wrapperRef.current?.children || []

      Array.from(elements).forEach((element, index) => {
        const pos = randomHoverPositions[index]
        const x = (window.innerWidth - event.clientX * pos) / intensity
        const y = (window.innerHeight - event.clientY * pos) / intensity
        element.style.transform = `translateX(${x}px) translateY(${y}px)`
      })
    }

    window.addEventListener('mousemove', parallax)

    return () => {
      window.removeEventListener('mousemove', parallax)
    }
  }, [randomHoverPositions])

  return (
    <div className={styles['parallax-texts-wrapper']} ref={wrapperRef}>
      {texts.map((text, index) => (
        <div
          key={`${text}-${index}`}
          className={styles['parallax-text']}
          style={{
            '--animation-time': `${randomFloatingDurations[index]}s`,
          }}
        >
          {text}
        </div>
      ))}
    </div>
  )
}

export default memo(ParallaxTexts)
